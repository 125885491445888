.vote-info {
  background-color: lightgray;
  margin: 1rem;
  padding: 1rem;
  p {
    font-family: Georgia, "Times New Roman", Times, serif;
    font-size: 1rem;
    font-weight: 600;
  }
}

.vote-subsection {
    text-align: center;
  h3 {
    font-size: 1.5rem;
    font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
    font-weight: 600;
    color: #a02936;
  }
}