.App-header {
    width: 100%;
    text-align: center;
    background-color: #a02936;
    color: white;
    font-family: 'Impact', sans-serif;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
      margin: 0.5rem;
    }
  }

/* Header.styles.css */

.App-logo {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  background-color: #004376;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.5);
}

.left-section,
.center-section,
.right-section {
  flex: 1;
  display: flex; /* Ensures the image container is also flexible */
  justify-content: center; /* Centers the image horizontally */
  align-items: center; /* Centers the image vertically */
}

img {
    max-width: 100%; /* Ensures image does not exceed container width */
    height: auto; /* Maintains aspect ratio */
    object-fit: contain; /* Ensures the image is fully visible without being cropped */
    cursor: pointer;
  }

.center-image {
    margin: 0;
    padding: 0;
    box-shadow: 0 18px 26px 6px rgba(0, 0, 0, 0.5);
  }

@media (max-width: 600px) {
  .App-logo {
    flex-direction: row;
  }

  .App-header {
    font-size: 0.5rem;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .App-logo {
    flex-direction: row;
  }

  .left-section,
  .center-section,
  .right-section {
    padding: 5px;
  }
  
  .App-header {
    font-size: 0.75rem;
  }
}


@media (min-width: 901px) {
  .App-logo {
    flex-direction: row;
  }

  .left-section,
  .center-section,
  .right-section {
    padding: 10px;
  }
}
