.hero {
    display: flex;
    flex-direction: column;
}

.hero-title {
    margin-bottom: 0;
}

.hero-text {
    color: #00508c;
    font-family: 'Impact', sans-serif;
    font-size: 1rem;
}

.hero-subtext {
    font-family: 'Arial', sans-serif;
    font-style: italic;
    font-size: 1rem;
    color: #181315;
}

@media (min-width: 768px) {
    .hero-text {
        font-size: 2rem;
    }
    .hero-subtext {
        font-size: 2rem;
    }
    .hero-body { 
        padding-left: 3rem;
    }
}
