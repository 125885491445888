.info-section {
  h1 {
    font-size: 1.5rem;
    font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
    font-weight: 600;
    color: #00508c;
  }
}

.info-list {
  li {
    color: #181315;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-weight: 600;
    padding: 0.5rem;
  }
}

.info-subsection {
  border: #cbeffd 2px solid;
  background-color: #cbeffd;
  text-align: center;
  h4 {
    font-size: 1rem;
    font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
    font-weight: 800;
    color: #a02936;
  }
}

@media (min-width: 768px) {
  .info-section {
    margin: 0;
    padding: 0;
    h1 {
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      font-weight: 600;
      font-size: 2rem;
    }
  }
  .info-list {
    li {
      font-size: 1.25rem;
      font-family: Georgia, 'Times New Roman', Times, serif;
      color: #181315;
    }
  }
  .info-subsection {
    h4 {
      font-size: 1.5rem;
      font-weight: 800;
    }
  }
}
