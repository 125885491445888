/* NavBar.styles.css */

.navigation {
  background: #004376;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  color: #fff;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}

.nav-toggle {
  display: none;
  background: #a02936;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.nav-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
  align-items: center;
  width: 100%;
}

.nav-item {
  padding: 10px;
}

@media (max-width: 768px) {
  .nav-toggle {
    display: block;
    border-radius: 5px;
  }

  .nav-list {
    display: flex;
    position: absolute;
    flex-direction: column;
    background: #a02936;
    width: 100%;
    top: 100%;
    left: 0;
    display: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  .nav-list.open {
    display: flex;
  }

  .nav-item {
    text-align: center;
    justify-content: center;
    width: 100%;
    padding-top: 18px;
    border-top: 1px solid #ccc;
  }

  .nav-item a {
    display: block;
    padding: 0;
  }
}
