.App {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
}

.navigation {
  display: flex;
  min-width: none;
  padding: 1em;
}

.nav-list {
  list-style: none;
  display: flex;
  flex-direction: row;
}

.nav-item {
  margin-right: 20px;
}

.nav-item a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.nav-item a:hover,
.nav-item a:active {
  text-decoration: underline;
}

.App-body {
 max-width: 60vw;
 min-width: 480px;
 margin: auto;
}

.footer {
  background-color: #004376;
  color: white;
  padding: 1rem;
  text-align: center;
  margin: 0;
  h5 {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 1.25rem;
    margin: 0.25rem;
  }
  a {
    text-decoration: none;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: red;
    font-size: 1.25rem;
    font-weight: bolder;
    cursor: pointer;
  }
}

@media (max-width: 480px) {
  .App {
    display: flex;
    flex: 1;
  }

  .App-body {
    max-width: 100%; 
    min-width: auto;
    padding: 0 1rem;
  }


  .App-logo {
    padding-top: 1em;
    padding-bottom: 1em;
  } 

  .nav-item {
    margin-bottom: 10px;
    margin-right: 0;
  }

  .nav-item a {
    font-size: 1rem;
    padding: 0.5rem; 
  } 
  

  .footer {
    padding: 1rem;
  }
}